/* eslint-disable no-undef */
import React from 'react';
import ReactGA from 'react-ga';
import ButtonClassic from '../Atoms/ButtonClassic';

export default function MenuNav(props) {

	const {scrollPosition} = props;

	function slideToSection(link){
		document.location = link
		if(link === "#contact_us"){
			ReactGA.event({
				category: 'contact',
				action: 'Send a mail'
			});
		}
		props.scrollMove(link.replace('#', ''))
	}

	return (
		<nav id="menu-nav" className={`main_grid_pad`}>
				<div className="logo-container">
					<img onClick={() => document.location = "/"} className="logo" src="/images/logo.png" alt="Logo Amitel" />
				</div>
				<div className="links-container">

					<span className={`link ${scrollPosition === "welcome" ? 'active' : ''}`} onClick={() => slideToSection("#welcome")}>Accueil</span>
					<span className={`link ${scrollPosition === "home" ? 'active' : ''}`} onClick={() => slideToSection("#home")}>Activités</span>
					<span className={`link ${scrollPosition === "who_we_are" ? 'active' : ''}`} onClick={() => slideToSection("#who_we_are")}>Qui sommes-nous ?</span>
					<span className={`link ${scrollPosition === "key_numbers" ? 'active' : ''}`} onClick={() => slideToSection("#key_numbers")}>Nos fondamentaux</span>
					<span className={`link ${scrollPosition === "activities" ? 'active' : ''}`} onClick={() => slideToSection("#activities")}>Secteurs d'activité</span>
					<ButtonClassic callBack={() => slideToSection("#contact_us") } label="Contact"/>
				</div>
			</nav>
	)
}
