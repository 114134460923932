export default {
	LOCAL_URL: process.env.REACT_APP_API_URL,
	CONTACT_API: '/api/contact_requests',
	CAPTCHA: process.env.REACT_APP_CAPTCHA,

    API_METHOD: 'POST',
    API_DEFAULT_HEADERS: {
        'Content-Type': 'application/ld+json',
        'Accept': 'application/ld+json',
    },

    GRAPHQL_URL: '/api/graphql',
    GRAPHQL_METHOD: 'POST',
    GRAPHQL_DEFAULT_HEADERS: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    DEFAULT_LIST_OFFSET: 10,
    DEFAULT_CARD_LIST_OFFSET: 12
};