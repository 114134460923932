import React from 'react'
import PropTypes from 'prop-types';

function ListRender(props) {
	return (
		<ul className="list_component">
			{props.list.map((item,i) => (
				<li key={i}>
					{item.title}
					{item.content && item.content.length > 0 && (<ul>
						<li className="text" dangerouslySetInnerHTML={{__html: item.content}} />
					</ul>)}
				</li>
			))}
		</ul>
	)
}

ListRender.propTypes = {
	tabs: PropTypes.array
}

export default ListRender
