import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import ReactGA from 'react-ga';
import React from 'react';
import { hydrate, render } from "react-dom";
import RouterFront from './Router.front'

import * as serviceWorker from './serviceWorker';

(function initializeReactGA() {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
    ReactGA.pageview('/');
})()

const rootElement = document.getElementById("root");



if (rootElement.hasChildNodes()) {
  hydrate(<RouterFront />, rootElement);
} else {
  render(<RouterFront />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
