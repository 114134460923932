import React, {useEffect, useState} from 'react'
import DeviceDetector from '../Helpers/deviceDetector'


export default function ScrollButton(props) {

	let scrolling = false;
	let newPosition;
	const [scrollListenerState, setScrollState] = useState(false)

	function scrollListenner(){
		if(!scrollListenerState){
			window.addEventListener('wheel',directionDetected)
			return setScrollState(true);
		}
	}
	
	
	function directionDetected(e){

		const scrollTest = e.deltaY;
		if(scrolling === false ){
			if(scrollTest < 0){
				return slideToTop()
			}else if(scrollTest > 0){
				return slideToBott()
			}
		}
	}

	function toggleScrolling(state){
		return scrolling = state;
	}

	function windowURL(){
		if(window.location.hash.length > 0){
			return window.location.hash.replace('#', '');
		}
		return 'home';
	}

	function slideToBott(){
		if(document.getElementById(windowURL()).nextElementSibling !== null && !scrolling){
			toggleScrolling(true);
			if(document.getElementById(windowURL()).nextElementSibling.classList.contains('slide')){
				newPosition = "home"
				document.location = '#'+newPosition
				props.scrollMove(newPosition)
			}else{
				newPosition = document.getElementById(windowURL()).nextElementSibling.attributes.id.nodeValue
				document.location = '#'+newPosition
				props.scrollMove(newPosition)
			}
			setTimeout(() => toggleScrolling(false), 800);
		}
	}
	function slideToTop(){
		if(document.getElementById(windowURL()).previousElementSibling !== null){
			if(document.getElementById(windowURL()).previousElementSibling.attributes.id.nodeValue !== "scrollToBott" && !scrolling){
				toggleScrolling(true);
				newPosition = document.getElementById(windowURL()).previousElementSibling.attributes.id.nodeValue
				
				document.location = '#'+newPosition
				props.scrollMove(newPosition)
				setTimeout(() => toggleScrolling(false), 800);
			}
		}
	}
	

	function directionPad(){
		window.addEventListener('keydown', function(e){
			if(e.key === "PageUp"){
				props.scrollMove('#welcome')
				document.location ='#welcome';
			}else if(e.key === "PageDown"){
				props.scrollMove('#contact_us')
				document.location = '#contact_us';	
			}else if(e.key === "ArrowUp"){	
				slideToTop();
			}else if(e.key === "ArrowDown"){
				slideToBott();
			}
		})
	}


	useEffect(() => {
		if(scrollListenerState === false){
			if(DeviceDetector() === "laptop" || DeviceDetector() === "desktop"){
				scrollListenner();
				directionPad();
			}
		}
	})

	return (
		<button id="scrollToBott" className={`scroll-button ${props.scrollPosition === 'contact_us' ? 'hidden' : ''}`}  onClick={slideToBott}>
			<div className="arrow-bottom" />
		</button>
	)
}
