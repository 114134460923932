import Constants from "./global.constants";

export default async function SendMail(dataMail) {

	const name = dataMail.name;
	const email = dataMail.email;
	const message = dataMail.message;

	try {
		const res = await fetch(
			Constants.LOCAL_URL + Constants.CONTACT_API,
			{
				method: Constants.API_METHOD,
				headers: Constants.API_DEFAULT_HEADERS,
				body: JSON.stringify(
					{
						name,
						email,
						message
					}
				)
			}
		);

		if (res.ok) {
			return {success : true};
		}else{
			console.log('something wrong happened');
			return {success : false};
		}
	} catch(err){
		console.log('an error occured : ', err)
	}
}
