import React, {useState, useEffect, createRef} from 'react'
import SendMail from '../../context/sendMail';
import TextInput from '../Atoms/TextInput';
import MailInput from '../Atoms/MailInput';
import TextArea from '../Atoms/TextArea';
import ButtonClassic from '../Atoms/ButtonClassic';
import PopUp from '../Molecules/PopUp';
import ReCAPTCHA from "react-google-recaptcha";
import Constants from '../../context/global.constants';
import {actualNavigator} from '../Helpers/navigatorDetector'

function HomeForm(props) {

	const [nameContact, setNameContact] = useState('');
	const [mailContact, setMailContact] = useState('');
	const [messageContact, setMessageContact] = useState('');

	const [mailSentState, setMailSentState] = useState(false);
	const [successMail, setSuccessMail] = useState(false);
	const [resetInputsState, resetInputsSet] = useState(false);
	const [captchaState, setCaptchaState] = useState(false)

	const formRef = createRef();

	async function handleSubmit(e){
		
		e.preventDefault();
		
		if(nameContact.length > 2 && mailContact.length > 0 && messageContact.length > 30){
			const varSendMail = await SendMail({name: nameContact, email: mailContact, message: messageContact});
			if (varSendMail) {
				setMailSentState(true)
				document.querySelectorAll('.err_message').forEach(errMess => errMess.remove());
				if(varSendMail.success === true) {
					setSuccessMail(true)
				}
			};
		}else{
			
			// Name ! ========================================
			if(nameContact.length < 2){
				if(formRef.current !== null ){
					if(formRef.current.children[0].querySelector('.err_message') === null){
						formRef.current.children[0].insertAdjacentHTML('beforeend', `<div class="err_message">Veuillez renseigner votre nom.</div>`)
					}
				}
			}else{
				if(formRef.current !== null ){
					if(formRef.current.children[0].querySelector('.err_message') !== null){
						formRef.current.children[0].querySelector('.err_message').remove()
					}
				}
			}
			
			// Mail ! ========================================
			if(mailContact.length < 0){
				if(formRef.current !== null ){
					if(formRef.current.children[1].querySelector('.err_message') === null){
						formRef.current.children[1].insertAdjacentHTML('beforeend', `<div class="err_message">Veuillez renseigner votre email.</div>`)
					}
				}
			}else{
				if(formRef.current !== null ){
					if(formRef.current.children[1].querySelector('.err_message') !== null){
						formRef.current.children[1].querySelector('.err_message').remove()
					}
				}
			}
			
			
			// Message ! ========================================
			if(messageContact.length < 30){
				if(formRef.current !== null ){
					if(formRef.current.children[2].querySelector('.err_message') === null){
						formRef.current.children[2].insertAdjacentHTML('beforeend', `<div class="err_message">Votre message semble être court !</div>`)
					}
				}
			}else{
				if(formRef.current !== null ){
					if(formRef.current.children[2].querySelector('.err_message') !== null){
						formRef.current.children[2].querySelector('.err_message').remove()
					}
				}
			}

		}
	}

	function hiddePopUp(){
		setNameContact('')
		setMailContact('')
		setMessageContact('')
		resetInputsSet(true)
		for(let i = 0;  i < 3 ; i++){
			if(formRef.current !== null){
				formRef.current[i].value = '';
				formRef.current[i].classList.remove('active');
			}
		}
		setMailSentState(false);
		setSuccessMail(false);
		resetInputsSet(false)
	}

	function popUpAction(){
		if(successMail === true){
			return <PopUp type="success" message="Votre message a bien été envoyé !" endPopUp={(e) => hiddePopUp(e)}/>;
		} else {
			return <PopUp type="error" message="Oups ! Il y a eu un soucis, veuillez réessayer plus tard." endPopUp={(e) => hiddePopUp(e)}/>;
		}
	}

	function CaptchaCheck(e){
		setCaptchaState(true)
	}


	useEffect(()=>{
		if(window.innerHeight < 775){
			document.querySelector('.home_form_container').style.maxHeight = (window.innerHeight - 200 )+"px";
			document.querySelector('.home_form_container').style.overflowY = "scroll";

			if(actualNavigator === 'firefox'){
				document.querySelector('.home_form_container').addEventListener('DOMMouseScroll', (e) => e.cancelBubble = true );
			}else{
				document.querySelector('.home_form_container').addEventListener('DOMMouseScroll', (e) => e.cancelBubble = true );
			}
		}
	})

	return (
		<>
			<form onSubmit={handleSubmit} ref={formRef} className="small_grid_pad">
				<TextInput reset={resetInputsState} valueInput={setNameContact} label="Votre nom" idu="contact_name"/>
				<MailInput reset={resetInputsState} valueInput={setMailContact} label="Votre adresse électronique"  idu="contact_mail"/>
				<TextArea reset={resetInputsState} valueInput={setMessageContact} label="Votre message" idu="contact_message"/>
				<ReCAPTCHA
					sitekey={Constants.CAPTCHA}
					onChange={CaptchaCheck}
				/>
				{captchaState && <ButtonClassic label="Envoyer votre message" type="submit" idu="contact_name"/>}
				
			</form>
			{mailSentState && popUpAction()}
		</>
	)
}

HomeForm.propTypes = {

}

export default HomeForm

