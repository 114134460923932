import React, {createRef, useEffect} from 'react'
import PropTypes from 'prop-types'

function PopUp(props) {

	const popUpRef = createRef(); 

	setTimeout(() => {
		if (popUpRef.current) {

			popUpRef.current.style.transition = "0.1s";
			popUpRef.current.style.transform = "scale(1.2)";
			
			popUpRef.current.style.transition = "0.3s";
			popUpRef.current.style.transform = "scale(0) translateY(-200px)";

			props.endPopUp()
		}
	}, 1500)
	
	useEffect(() => {
		if(popUpRef.current !==  null ){

			popUpRef.current.style.transition = "0s";
			popUpRef.current.style.transform = "scale(0) translateY(-200px)";
			
			popUpRef.current.style.transition = "0.3s";
			popUpRef.current.style.transform = "scale(1) translateY(0px)";
		}
	},[popUpRef])




	if(props.type === 'success'){
		return (
			<div ref={popUpRef} className="popup_component success">
				<div className="message">
					<img className="picto" src="/images/pictos/checked.svg" alt="success picto" /><span>{props.message}</span>
				</div>
			</div>
		)
	}else if(props.type === 'error'){
		return (
			<div ref={popUpRef} className="popup_component error">
				<div className="message">
					<img className="picto" src="/images/pictos/error.svg" alt="error picto" /><span>{props.message}</span>
				</div>
			</div>
		)
	}
}

PopUp.propTypes = {
	type: PropTypes.string,
	message: PropTypes.string,
	endPopUp: PropTypes.func
}

export default PopUp

