import React from 'react';
import PropTypes from 'prop-types';

function ButtonClassic(props) {

	function sendCallBack(){
		props.callBack && props.callBack('click');
	}

	if(props.type === "submit"){
		return (
			<button type="submit" className={`btn-classic ${props.theme ? props.theme : ''}`} >
				{props.label}
			</button>
		)
	}else if(props.type === "link"){
		return (
			<a href={props.link} className={`btn-classic ${props.theme ? props.theme : ''}`}>
				{props.label} 
			</a>
		)
	}else if(props.type === "anchor"){
		return (
			<button onClick={() => sendCallBack()} className={`btn-classic ${props.theme ? props.theme : ''}`} >
				{props.label}
			</button>
		)
	}else{
		return (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a onClick={()=> sendCallBack()} type="button" className={`btn-classic ${props.theme ? props.theme : ''}`} >
				{props.label} 
				{props.arrow ? <img className="picto" src="/images/pictos/arrow.svg" alt="" /> : ''}
			</a>
		)
	}
}

ButtonClassic.propTypes = {
	label: PropTypes.string.isRequired,
	theme: PropTypes.string,
	type: PropTypes.string,
	callBack: PropTypes.func,
	link: PropTypes.string,
	anchor: PropTypes.string,
	arrow: PropTypes.bool
}

export default ButtonClassic

