export default function() {
const winWidth = window.innerWidth;

let device;

if(winWidth<767) device = "phone"
else if(winWidth<1023) device = "tablet"
else if(winWidth<1279) device = "laptop"
else if(winWidth>1280) device = "desktop"
else device = 'none'

	return device;
}