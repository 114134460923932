import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import ButtonClassic from '../Atoms/ButtonClassic';
import textCleaner from '../Helpers/textCleanerTags'

export default function Slider(props) {


	const defaultActive = props.defaultSlideActive
	const tabActiveInitialize = props.tabs.map((tab, i)=> { 
		return {
			...tab, 
			active: (Number(defaultActive) === i) ? true : false, 
			_id: i
		}
	});
	const [tabsList, tabsAction] = useState(tabActiveInitialize);
	const [animState, animAction] = useState(false);
	const [prevSlideId, setPrevSlideId] = useState(0);
	const [togglerTextState, setTogglerText] = useState(false);
	const [hasSwipped, setSwippedState] = useState(false);

	function togglerText(e){
		if(e === 'click'){
			setTogglerText(true)
		}
	}

	const sliderRef = createRef();
	
	const sliderTextRender = (tab) =>  {
		if(togglerTextState || window.innerWidth > 1024){
			return (<p className={`text ${textCleaner(tab.content).length > 250 ? 'scrollable-content' : ''}`} dangerouslySetInnerHTML={{__html: tab.content}} />)
		}else{
			return (
				<>
					<div className="mobile_text">
						<p className={`text`} dangerouslySetInnerHTML={{__html: tab.content.substring(0, 250)}} />
					</div>
					<ButtonClassic type="buttton" callBack={(e) => togglerText(e)} label="Lire la suite" theme="white"/>
				</>
			)
		}
	}
	
	function removeMoveListener(e){
		e.target.removeEventListener('pointermove', writeData)
		e.target.removeEventListener('touchmove', writeDataMobile)
	}

	function writeData(e){
		if(e.movementX < 0){
			const targetID = Number(e.target.dataset.idSlide) + 1;
			if(targetID < 3) activeSlide(targetID)
			removeMoveListener(e)
		}else{
			const targetID = Number(e.target.dataset.idSlide) - 1;
			if(targetID > -1) activeSlide(targetID)
			removeMoveListener(e)
		}
	}

	let actualSwipPos, newSwipPos;

	function writeDataMobile(e){
		newSwipPos = e.touches[0].clientX;

		if(newSwipPos < actualSwipPos){
			const targetID = Number(e.target.dataset.idSlide) + 1;
			if(targetID < 3) activeSlide(targetID)
			removeMoveListener(e)
		}else{
			const targetID = Number(e.target.dataset.idSlide) - 1;
				if(targetID > -1) activeSlide(targetID)
				removeMoveListener(e)
		}
	}
	
	function mouseDownSlide(e){
		actualSwipPos = (e.touches !== undefined ? e.touches[0].clientX : e.clientX);
		if(window.innerWidth > 1023){
			e.target.addEventListener('pointermove', writeData)
		}else{
			e.target.addEventListener('touchmove', writeDataMobile)
		}
	}

	function activeSlide(idTarget){
		const idSlide = idTarget;
		const activeTab = tabsList.filter(tab => (tab.active === true && tab._id === Number(idSlide)));
		if(activeTab.length !== 1){
			props.changePosition !== undefined && props.changePosition();
			changeSlide(idSlide);
			tabsAction(prevState => prevState.map(tab => (tab._id === Number(idSlide)) ? {...tab, active: true} : {...tab, active: false}))
		}
	}

	
	function changeSlide(targetId){
		const slideId = Number(targetId);
		 setPrevSlideId(targetId);
		 setSwippedState(true);
		if(!animState){
			if(targetId > prevSlideId){
				animAction(true)

				const actualyActive = tabsList.filter(tab => tab.active === true);
				const otherOneNotActive = tabsList.filter(tab => tab.active === false && tab._id !== slideId);
				
				(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transition = '0s');
				document.querySelector('#'+props.idName+' .slide-'+slideId).style.transition = '0s';
				document.querySelector('#'+props.idName+' .slide-'+otherOneNotActive[0]._id).style.transition = '0s';

				document.querySelector('#'+props.idName+' .slide-'+slideId).style.zIndex = "9";
				document.querySelector('#'+props.idName+' .slide-'+otherOneNotActive[0]._id).style.zIndex = "2";
				(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.zIndex = "4");
				document.querySelector('#'+props.idName+' .slide-'+slideId).style.transform = `translateX(${window.innerWidth}px)`;

				setTimeout(()=>{
					
					(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transition = '0.6s ease-out');
					(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transform = `translateX(-${window.innerWidth/2}px)`);
					
					document.querySelector('#'+props.idName+' .slide-'+slideId).style.transition = '0.6s ease-out';
					document.querySelector('#'+props.idName+' .slide-'+slideId).style.transform = `translateX(0px)`;
					
				}, 10)
	
				setTimeout(() => animAction(false), 600);
			}else{
				animAction(true)
				const actualyActive = tabsList.filter(tab => tab.active === true);
				const otherOneNotActive = tabsList.filter(tab => tab.active === false && tab._id !== slideId);
				
				(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transition = '0s');
				document.querySelector('#'+props.idName+' .slide-'+slideId).style.transition = '0s';
				document.querySelector('#'+props.idName+' .slide-'+otherOneNotActive[0]._id).style.transition = '0s';

				document.querySelector('#'+props.idName+' .slide-'+slideId).style.zIndex = "9";
				(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.zIndex = "4");
				document.querySelector('#'+props.idName+' .slide-'+otherOneNotActive[0]._id).style.zIndex = "2";
				document.querySelector('#'+props.idName+' .slide-'+slideId).style.transform = `translateX(-${window.innerWidth}px)`;
				
				setTimeout(()=> {
					
					(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transition = '0.6s ease-out');
					(actualyActive.length > 0) && (document.querySelector('#'+props.idName+' .slide-'+actualyActive[0]._id).style.transform = `translateX(${window.innerWidth/2}px)`);
					
					document.querySelector('#'+props.idName+' .slide-'+slideId).style.transition = '0.6s ease-out';
					document.querySelector('#'+props.idName+' .slide-'+slideId).style.transform = `translateX(0px)`;
					
				}, 10)
	
				setTimeout(() => animAction(false), 600);
			}
		}
	}
	function swipperInvitation(){
		if(window.innerWidth < 1023){
			if(!hasSwipped){
				return(
					<div className="swipe_invitation" onClick={() => {setSwippedState(true); activeSlide(1)}} onTouchStart={() => {setSwippedState(true); activeSlide(1)}}>
						<img className="picto" src="/images/pictos/arrow_right.svg" alt="swipe invitation"/>
					</div>
				)
			}
		}
	}

	return (
		<div id={props.idName} className={`slider ${'theme-'+props.theme} ${props.scrollPosition}`} ref={sliderRef}>
			{swipperInvitation()}
			{tabsList.map(tab => (
				<div 
				key={tab._id} 
				onPointerDown={!animState ? mouseDownSlide : undefined}
				onTouchStart={!animState ? (window.innerWidth < 1023 ? mouseDownSlide : undefined) : undefined}
				className={`slide ${tab.keyNumbers ? 'keynumbers-slide' : ''} ${'slide-'+tab._id} ${tab.active ? 'visible' : 'not-visible'}`} 
				data-id-slide={tab._id}
				style={{background: 'url('+(window.innerWidth > 1023 ? tab.imageFilename : '')+')', backgroundSize: "cover",  backgroundColor: (props.theme === 'blue' )? 'rgb(50, 57, 67)' : 'rgb(50, 57, 67)'}}>
					<div data-id-slide={tab._id} key={tab._id} className="text-container main_grid_pad">
						<h2 className="title bordered-bott">
							{tab.title}
						</h2>
						{!tab.keyNumbers ?
							sliderTextRender(tab)
						: tab.keyNumbers.map((key, i) => {
							return (
								<div key={i} className="key-number">
									<span className="title">
										{key.number}
									</span>
									<span className="text">
										{key.title}
									</span>
								</div>
							)
						})}
					</div>
				</div>
				))}
			<div className="slider-selector-container main_grid_mar">
				{tabsList.map(tab => {
					return (
						<div 
						className={`slider-selector ${tab.active ? 'active' : ''}`} 
						data-active={tab.active ? 'active' : 'not-active'}
						key={tab._id} 
						onClick={()=> !animState && activeSlide(tab._id)}>
							<span className="slider-selector-label">{tab.title}</span>
						</div>
					)
				})}
			</div>
		</div>
	)
}

Slider.propTypes = {
	tabs: PropTypes.array,
	theme: PropTypes.string,
	idName: PropTypes.string,
	scrollPosition: PropTypes.string
}
