import React, {useState, useEffect, createRef} from 'react'
import PropTypes from 'prop-types';

function TextInput(props) {
	const [textvalue, setTextValue] = useState('');
	const [focus, setFocus] = useState(false);

	const inputRef = createRef();

	function sendData(e){
		setTextValue(e.target.value)
		return props.valueInput(e.target.value);
	}
	
	useEffect(() => {
		if(props.reset === true){
			setFocus(false)
			setTextValue('')
			if(inputRef.current !== null) {
				inputRef.current.value = '';
			}
		}
	}, [props.reset, inputRef])

	return (
		<div className={`field-form textinput_component ${(textvalue.length !== 0 || focus) ? 'active' : ''}`}>
			<label htmlFor={'#'+props.idu}>{props.label}</label>
			<input 
				onBlur={() => setFocus(false)}
				onFocus={() => setFocus(true)} 
				onChange={sendData} 
				id={props.idu} 
				ref={inputRef}
				type="email" 
				/>
		</div>
	)
}
TextInput.propTypes = {
	idu: PropTypes.string,
	label: PropTypes.string,
	reset: PropTypes.bool,
}
export default TextInput
