export default function resizeImg169(imgClass){
	const resizeAllImgs = () => {
		return document.querySelectorAll(imgClass).forEach((img)=> {
			const imgW = img.clientWidth;
			const imgH = imgW * 0.5625;
			img.style.height = imgH+'px';
		});
	}
	resizeAllImgs()
	window.addEventListener('resize', () => {
		resizeAllImgs()
	})
}