import React,{useState, useEffect} from 'react';
import '../style/app.scss';
import MenuNav from '../components/Molecules/MenuNav';
import Slider from '../components/Molecules/Slider';
import ScrollButton from '../components/Atoms/ScrollButton';
import FetchDataHomePage from '../context/fetchData';
import Loader from '../components/Atoms/Loader';
import ListRender from '../components/Atoms/ListRender';
import HomeForm from '../components/Molecules/HomeForm';
import SlideAlone from '../components/Molecules/SlideAlone'

import {Helmet} from "react-helmet";
import Footer from '../components/Molecules/Footer'

export default function App() {

	// Global state
	const [dataPage, setDataPage] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [welcomeAnimState, setWelcomeAnimState] = useState(false);

	
	const [scrollPosition, setNewPosition] = useState('home');


	async function dataGet(){
		const data = await FetchDataHomePage();
		return setDataPage(data);
	}

	function scrollPositionChange(newPosition){
		if(window.location.hash.length > 0){ 
			setNewPosition(newPosition.replace('#', ''))
			return newPosition.replace('#', '');
		}
		setNewPosition('welcome')
		return 'welcome';
	}

	window.onpopstate = () => {
		scrollPositionChange(document.location.hash);
	};

	useEffect(() => {
		if(dataPage.length === 0){
			dataGet();
		}else{
			setTimeout(() => setLoaded(true), 1000);
		}
		if(loaded === true && welcomeAnimState === false){
			document.querySelector('.page-container').style.opacity = "1";
			document.querySelector('#menu-nav').style.transform = "translateY(0%)";
			if(window.location.hash.length > 0) {
				scrollPositionChange(window.location.hash)
				document.location = window.location.hash;
			}else{
				scrollPositionChange('#welcome')
				document.location = "#welcome";
			};
			setWelcomeAnimState(true);
		}
	}, [dataPage.length, loaded, welcomeAnimState])
	
	if(loaded === true){
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>AMITEL – Editeur et prestataire de services</title>
					<meta name="description" content="Edition de logiciels pour les greffes de tribunaux de commerce, saisie et traitement de données, numérisation et dématérialisation de documents, éditique." />
					
					<meta property="og:title" content="AMITEL – Editeur et prestataire de services"/>
					<meta property="og:description" content="Edition de logiciels pour les greffes de tribunaux de commerce, saisie et traitement de données, numérisation et dématérialisation de documents, éditique."/>
					<meta property="og:type" content="video.movie" />
					<meta property="og:url" content="https://amitel.fr" />
					<meta property="og:image" content="https://amitel/images/logo.png" />
				</Helmet>
				<MenuNav  scrollMove={(move) => setNewPosition(move)} scrollPosition={scrollPosition} />
				<div className="page-container">
					<ScrollButton scrollMove={(move) => scrollPositionChange(move)} scrollPosition={scrollPosition} />
					{/* ============== HOME SECTION ============== */}
					<SlideAlone 
						changePosition={()=> {scrollPositionChange('#home'); document.location = "#home"}} 
						appearState={scrollPosition} 
						id="welcome" theme="red whited" 
						content={{image: dataPage.homes[0].imageFilename, title: dataPage.homes[0].title , content : dataPage.homes[0].content}}
						/>

					<Slider 
						defaultSlideActive="0" 
						scrollPosition={scrollPosition === 'home' ? 'active' : ''} 
						tabs={dataPage.homes[0].activities} 
						theme="red whited" 
						idName="home" 
						changePosition={() => scrollPositionChange('#home')}
						/>

					{/* ============== WHO WE ARE SECTION ============== */}
					<div className={`layer full-page flex-row main_grid_pad ${scrollPosition === 'who_we_are' ? 'active_anim_section' : 'not_active_anim_section'}`} id="who_we_are">
						<div className="main-content main">
							<div className="text-container small_grid_pad">
								<h2 className="title bordered-bott main_grid_pad_right">
									{dataPage.abouts[0].title}
								</h2>
								<p className="subtitle main_grid_pad_right" dangerouslySetInnerHTML={{__html: dataPage.abouts[0].intro}} />
								<p className="text main_grid_pad_right" dangerouslySetInnerHTML={{__html: dataPage.abouts[0].content}} />
							</div>
						</div>
						<div className="small_grid_pad banner">
							<div className="text-container">
								{dataPage.abouts[0].focusTitle.length > 3 ? <h2 className="bordered-bott title">{dataPage.abouts[0].focusTitle}</h2> : ''} 
								{dataPage.abouts[0].focuses.map((item, i) => (
									<div key={i} className="banner_line">
										<p className="banner_subtitle">{item.title}</p>
										<p className="banner_subcontent">{item.subtitle}</p>
									</div>
								))}
							</div>
						</div>
					</div>

				{/* ============== KEY NUMBERS SECTION ============== */}
								
				<Slider 
					scrollPosition={scrollPosition} 
					defaultSlideActive="0" 
					idName="key_numbers"  
					tabs={[dataPage.keyNumbersSections[0], ...dataPage.fundamentalBlocks]} 
					theme="blue"
					/>


				{/* ============== ACTIVITIES SECTION ============== */}
				<div id="activities" className={`layer full-page flex-row main_grid_pad business-sectors ${scrollPosition === 'activities' ? 'active_anim_section' : 'not_active_anim_section'}`}>
					<div className="text-container small_grid_pad">
						<h2 className="title bordered-bott main_grid_pad_right">
							Secteurs d'activité
						</h2>
						<div className="flex-row">
							{dataPage.businessSectors.map((sector, i) => {
								return (
									<div key={i} className="col half_small_grid_pad">
										{sector.imageFilename !== 'none'  && (
											<div className="img-container">
												<img className="img" src={`${sector.imageFilename}`} alt={sector.title}/>
											</div>
										)}
										<h3 className="bordered-bott">
											{sector.title}
										</h3>
										<ListRender list={sector.businessSectorItems} />
									</div>
								)
							})}
						</div>
					</div>
				</div>

				{/* ============== CONTACT SECTION ============== */}
				<div id="contact_us" className={`full-page contact_section ${scrollPosition === 'contact_us' ? 'active_anim_section' : 'not_active_anim_section'}`}>
					<div className="flex-row">
						<div className="col main_grid_pad_left">
							<div className="container home_form_container">
								<h2 className="bordered-bott small_grid_pad_left">{dataPage.contactSections[0].title}</h2>
								<p className="text small_grid_pad_left" dangerouslySetInnerHTML={{__html: dataPage.contactSections[0].intro}}/>
								<HomeForm />
							</div>
						</div>
						<div className="col">
							<div className="red_block flex-row main_grid_pad_right small_grid_pad_left">
								<div className="col">
									<p className="business_name">Amitel</p>
									<div className="address" dangerouslySetInnerHTML={{__html: dataPage.contactSections[0].address}}/>
								</div>
								<div className="col">
									<a href={'tel:'+dataPage.contactSections[0].phone.replace(/\D/g, '')} className="phone"><img className="picto" src="/images/pictos/phone.svg" alt="" /> {dataPage.contactSections[0].phone}</a>
									<p className="fax"><img className="picto" src="/images/pictos/fax.svg" alt="" /> {dataPage.contactSections[0].fax}</p>
								</div>
							</div>
							<div className="contact_map">
							</div>
						</div>
					</div>
					<Footer legalNotices={dataPage.modalPages[0]} data={dataPage.footerLinks}/>
				</div>

			</div>
		</div>
  );
}else{
	return (<Loader />)
}
}