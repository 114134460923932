import React, {useState, useEffect, createRef} from 'react'
import PropTypes from 'prop-types';

function TextArea(props) {

	const [textvalue, setTextValue] = useState('');
	const [focus, setFocus] = useState('');

	const inputRef = createRef();
	
	function sendData(e){
		setTextValue(e.target.value)
		return props.valueInput(e.target.value);
	}

	useEffect(() => {
		if(props.reset === true){
			setFocus(false)
			setTextValue('')
			if(inputRef.current !== null) {
				inputRef.current.value = '';
			}
		}
	}, [props.reset, inputRef])

	return (
		<div className={`field-form textarea_component ${(textvalue.length !== 0 || focus) ? 'active' : '' }`}>
			<label htmlFor={'#'+props.idu}>{props.label}</label>
			<textarea 
				onBlur={() => setFocus(false)}
				onFocus={() => setFocus(true)} 
				onChange={sendData} 
				rows="5" 
				id={props.idu} 
				ref={inputRef}
				type="text"
				/>
		</div>
	)
}
TextArea.propTypes = {
	idu: PropTypes.string,
	label: PropTypes.string,
	reset: PropTypes.bool,
}
export default TextArea
