import React from 'react'

function Loader() {
	return (
		<div className="loading">
				<div className="fakelogo">
					<span className="letter">a</span>
					<span className="letter">m</span>
					<span className="letter">i</span>
					<span className="letter">t</span>
					<span className="letter">e</span>
					<span className="letter">l</span>
				</div>
			</div>
	)
}

export default Loader
