import React, {useEffect} from 'react';
// import routesReq from './graphql/routes.req';
// import Constants from './context/global.constants';
import resizeImg169 from '../components/Helpers/resizeImg16.9';
import MenuClassicPage from '../components/Molecules/MenuClassicPage'

export default function ClassicPage(props) {

	// async function fetchClassicPage (){
	// 	const res = await fetch(
	// 		'http://amitel.local'+Constants.GRAPHQL_URL,
	// 		{
	// 			method: Constants.GRAPHQL_METHOD,
	// 			headers: Constants.GRAPHQL_DEFAULT_HEADERS,
	// 			body: JSON.stringify({query: routesReq(stringCleaner(match.url))})
	// 		}
	// 	);
	// }

	useEffect(() => {
		resizeImg169('.header img')
	})
	
	const pageContent = props.classicProps

	document.querySelector('html').style.overflowY = "scroll";

	return (
		<div className="classic_page ">
			<MenuClassicPage/>
			<div className="header main_grid_pad">
				<img alt="something" src={pageContent.image}  />
			</div>
			<div className="text-container main_grid_pad">
				<h1 className="title">
					{pageContent.title}
				</h1>
				<div className="text" dangerouslySetInnerHTML={{__html: pageContent.content}}/>
			</div>
		</div>
	)
}
