import Constants from "./global.constants";
import HomeRequest from '../graphql/completepage.req';
const Humanize = require('humanize-graphql-response');

export default async function FetchDataHomePage() {
		const res = await fetch(
			Constants.LOCAL_URL + Constants.GRAPHQL_URL,
			{
				method: Constants.GRAPHQL_METHOD,
				headers: Constants.GRAPHQL_DEFAULT_HEADERS,
				body: JSON.stringify({query: HomeRequest})
			}
		);

		if (res.ok) {
			const json = await res.json();
			const goodData = Humanize(json)
			return goodData.data;
		}else{
			console.log('Something wrong happened');
			return 'Something wrong happened';
		}
}
