import React, {useEffect, createRef} from 'react'
import PropTypes from 'prop-types'
import ButtonClassic from '../Atoms/ButtonClassic';

function PopupCookie(props) {


	function closePopUp(){
		props.close(false)
	}
	function openCookiesManager(){
		const shader = document.querySelector('#tarteaucitronBack');
		const modal = document.querySelector('#tarteaucitron');


		shader.style.display === "none" ? shader.style.display = "block" : shader.style.display = "none" ;
		modal.style.display === "none" ? modal.style.display = "block" : modal.style.display = "none" ;
	}

	const popUpRef = createRef();

	useEffect(() => {
		if(popUpRef.current !== null){
			popUpRef.current.addEventListener('DOMMouseScroll', (e) => e.cancelBubble = true );
			popUpRef.current.addEventListener('mousewheel', (e) => e.cancelBubble = true );
		}
	})

	if(props.open){
		return (
			<div
			ref={popUpRef}
			 className={`popupCookie_component ${props.class}`}>
				<div className="layer_shaded" onClick={() => closePopUp()}/>
				<div className="container">	
					<div className="close_icon" onClick={() => closePopUp()}>
						<img className="picto" src="/images/pictos/close.svg" alt="close pop up" />
					</div>
					<h3 className="title_content bordered_bott" dangerouslySetInnerHTML={{__html: props.content.title}}/>
					{props.class === "legal-notices" && (

						<ButtonClassic callBack={() => openCookiesManager()} label="Gérer les cookies" type="button" theme="red"/> 
					)}
					<div className="content"  dangerouslySetInnerHTML={{__html: props.content.content}}/>
				</div>
			</div>
		)
	}else{
		return (
			<></>
		)
	}
}

PopupCookie.propTypes = {
	content: PropTypes.object,
	open: PropTypes.bool,
	close: PropTypes.func,
	class: PropTypes.string
}

export default PopupCookie


