import React, {useState, useEffect, createRef} from 'react'
import PropTypes from 'prop-types';
import ButtonClassic from '../Atoms/ButtonClassic';

function SlideAlone(props) {
	

	const [stateSlideHidden, setStateSlideHidden] = useState('')
	const {title, content, image} = props.content;

	const slideAloneRef = createRef()
	
	function setStateSlideFunc(){
		setStateSlideHidden('hidden')		
		props.changePosition()
	} 

	useEffect(() => {
		if(window.innerWidth > 1024){
			if(props.appearState === "welcome"){
				setStateSlideHidden('');
			}else{
				setStateSlideHidden('hidden')
			}
		}
	}, [props.appearState, props.id, slideAloneRef, stateSlideHidden])
		return (
			<div
				ref={slideAloneRef}
				id={props.id}
				style={{background: 'url('+(window.innerWidth > 767 ? image : '')+')', backgroundSize: "cover", backgroundColor: (props.theme === 'blue' )? '#477695' : 'rgb(50, 57, 67) '}} 
				className={`slide_alone_component slide_theme_${props.theme} `}>
				<div className={`text_container ${stateSlideHidden}`}>
					<h1 className="title bordered-bott">
						{title}
					</h1>
					<p className="text" dangerouslySetInnerHTML={{__html: content}} />
					<ButtonClassic callBack={() => setStateSlideFunc("#contact_us") } label="Découvrir nos activités" arrow/>
				</div>
			</div>
		)
}

SlideAlone.propTypes = {
	appearState: PropTypes.string,
	content: PropTypes.object.isRequired,
	theme: PropTypes.string,
	id: PropTypes.string,
}

export default SlideAlone

