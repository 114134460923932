import React, {useState} from 'react'
import PopupCookie from '../Molecules/PopupCookie'
import PropTypes from 'prop-types';

function Footer(props) {

	const [popUpState, setPopUp] = useState(false);

	return (
		<footer className="flex-row">
			<div className="col">
				<span className="reference">Amitel 2019 Tous droits réservés</span>
				<div className="links-container">
					<span className="link" onClick={() => setPopUp(true)}>Mentions légales</span>
					{props.data.map( link => {
						if(link.openInNewTab){
							return (<a key={link._id} rel="noopener noreferrer" target="_blank" className="link" href={link.link}>
								{link.title}
							</a>)
						}
						return (<a key={link._id} className="link" href={link.link}>
							{link.title}
						</a>)
					})}
				</div>
			</div>
			<div className="col">
				<a rel="noopener noreferrer" target="_blank" href="https://www.intuitiv-technology.com/" className="reference">Développé par Intuitiv Technology</a>
			</div>
			<PopupCookie class="legal-notices" content={props.legalNotices} open={popUpState} close={() => setPopUp(false) } />
		</footer>
	)
}
Footer.propTypes = {
	data: PropTypes.array
}

export default Footer
