import React from 'react'
import ButtonClassic from '../Atoms/ButtonClassic';

export default function MenuClassicPage() {
	return (
		<nav id="menu-nav" className="main_grid_pad">
			<div className="logo-container">
				<img className="" src="" alt="Logo Amitel" />
			</div>
			<ButtonClassic label="Retour" type="link" link="/" />
		</nav>
	)
}
