const homepageRequest = `
{
	homes(first: 2) {
	  edges {
		node {
		  _id
		  imageFilename
		  title
		  content
		  activities(first: 3) {
			edges {
			  node {
				_id
				title
				content
				imageFilename
			  }
			}
		  }
		}
	  }
	}
	abouts(first: 4){
	  edges{
		node{
		  _id
		  title
		  intro
		  content
		  focusTitle
		  focuses(first:10){
			edges{
			  node{
				_id
				title
				subtitle
			  }
			}
		  }
		}
	  }
	}
	fundamentalBlocks(first: 3){
	  edges{
		node{
		  _id
		  imageFilename
		  title
		  content
		}
	  }
	}
	keyNumbersSections(first: 10){
	  edges{
		node{
		  _id
		  imageFilename
		  title
		  keyNumbers(first: 5){
			edges{
			  node{
				_id
				title
				number
			  }
			}
		  }
		}
	  }
	}
	businessSectors(first: 4){
	  edges{
		node{
		  _id
		  imageFilename
		  title
		  businessSectorItems(first: 20){
			edges{
			  node{
				_id
				title
				content
			  }
			}
		  }
		}
	  }
	}
	contactSections(first: 2){
	  edges{
		node{
		  title
		  intro
		  address
		  phone
		  fax
		}
	  }
	}
	footerLinks(first:4){
		edges{
		  node{
			_id
			title
			link
			openInNewTab
		  }
		}
	  }
	modalPages(first: 10){
		edges{
			node{
				_id
				title
				content
			}
		}
	}
  }
   
`;
export default homepageRequest;